<template>
  <v-container style="max-width: 600px; background-color: #f5f5f5" class="pa-0 pt-5">
    <br />
    <div class="text-center">返金履歴</div>
    <br />
    <v-card class="my-4 mx-8">
      <v-list dense v-for="payment in payments" v-bind:key="payment._id">
        <PaymentCard isReturn :dataSource="payment" class="px-2" />
        <v-divider></v-divider>
      </v-list>
      <v-btn block v-on:click="fetchPayments">more</v-btn>
    </v-card>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';
  import PaymentCard from './components/PaymentCard.vue';

  export default {
    name: 'ReturnOrderHistory',

    components: {
      PaymentCard,
    },

    data: () => ({
      orderPage: 0,
      orders: [],
      loading: true,
      paymentPage: 0,
      payments: [],
      status_in: JSON.stringify([3]),
    }),

    async created() {},

    mounted() {
      this.fetchPayments();
    },
    methods: {
      async fetchOrders() {
        try {
          let { data } = await Methods.GetOrdersByUser({
            user_id: this.$store.state.user.user_id,
            // user_id: '632975baf7307c037aee91a9', // for test
            sortField: 'createdAt',
            sortOrder: -1,
            page: this.orderPage,
            perPage: 5,
          });
          this.orders = [...this.orders, ...data.orders];
          this.orderPage += 1;
        } catch (error) {
          console.log(error);
        }
      },
      async fetchPayments() {
        try {
          let { data } = await Methods.getPayments({
            user_id: this.$store.state.user.user_id,
            // user_id: '632975baf7307c037aee91a9', // for test
            status_in: this.status_in,
            sortField: 'createdAt',
            sortOrder: -1,
            page: this.paymentPage,
            perPage: 5,
          });
          this.payments = [...this.payments, ...data.payments];
          this.paymentPage += 1;
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>
